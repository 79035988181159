import { EtapesApiClientModel, EtapesApiClientInterface, EtapesApiClientUrlsInterface } from '@/models/api-client/etapes'

const urls: EtapesApiClientUrlsInterface = {
    getEtapes: process.env.VUE_APP_API_URL + '/api/etapes',
    getEtape: process.env.VUE_APP_API_URL + '/api/etapes/$(etapeId)',
    postEtape: process.env.VUE_APP_API_URL + '/api/etapes',
    putEtape: process.env.VUE_APP_API_URL + '/api/etapes/$(etapeId)',
    deleteEtape: process.env.VUE_APP_API_URL + '/api/etapes/$(etapeId)'
}
// instantiate the EtapesApiClient pointing at the url that returns static json mock \data
const etapesApiClient: EtapesApiClientInterface = new EtapesApiClientModel(urls)
// export our instance
export default etapesApiClient
