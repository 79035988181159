<template>
  <div class="login-body">
    <div class="body-container">
      <div class="p-grid">
        <div class="p-col-12 p-lg-6 left-side">
          <router-link to="/">
            <img
              :src="'assets/layout/images/logo.gif'"
              alt="Spirit"
            />
          </router-link>
          <h1>Page non autorisée</h1>
          <p>
            Vous n'avez pas les autorisations nécéssaires pour acceder à cette page.
          </p>
          <p>
            En cas de problème, contactez un administrateur pour vérifier vos droits.
          </p>
        </div>
        <div class="p-col-12 p-lg-6 right-side">
          <div class="login-wrapper">
            <div class="login-container">
              <router-link to="/">
                <Button
                  label="Retourner sur la page d'accueil"
                  icon="pi pi-check"
                  iconPos="right"
                />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import Button from "primevue/button";

export default defineComponent({
  name: "Start",
  components: {
    Button,
  },
  setup() {
    const route = useRoute();
    const password = ref("");

    return {};
  },
});
</script>