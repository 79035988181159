
import { defineComponent, ref, computed } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import ModeleProjet from "@/models/modele_projet/Modele_projet";
import Product from "@/models/product/Product";
import apiClient from "@/api-client";
import router from "@/router";
import Card from "primevue/card";
import { useUsersStore } from "@/store/users";
import MultiSelect from 'primevue/multiselect';
import ModeleProjetEtapeForm from '@/components/modele_projet_etape/ModeleProjetEtapeForm.vue'

export default defineComponent({
  components: {
    InputText,
    Button,
    Card,
    MultiSelect,
    ModeleProjetEtapeForm,
  },
  props: {
    modeleProjetId: {
      type: String,
    },
  },
  setup(props) {
    const toast = useToast();
    const confirm = useConfirm();
    const modele_projet = ref<ModeleProjet>();
    const usersStore = useUsersStore();
    const products = ref<Product[]>();
    const saveAll = ref<boolean>();

    saveAll.value = false;

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    apiClient.product
        .getProducts()
        .then((data: Product[]) => {
          products.value = data.map((product) => Object.assign(new Product(), product));
        });

    modele_projet.value = new ModeleProjet();
    modele_projet.value.id = props.modeleProjetId!;

    if (props.modeleProjetId != 'notSet') {
      apiClient.modeles_projet
        .getModeleProjet(modele_projet.value!)
        .then((data: ModeleProjet) => {
          modele_projet.value = Object.assign(new ModeleProjet(),data);
        });
    }

    const newEtape = () => {
      modele_projet.value!.etapesModele!.push('notSet');
    };

    const saveModeleProjet = () => {
      saveAll.value = true;
      const id = modele_projet.value!.id;
      apiClient.modeles_projet
        .sendModeleProjet(modele_projet.value!)
        .then((data: ModeleProjet) => {
          modele_projet.value = data;
          toast.add({
            severity: "success",
            summary: "Enregistré",
            detail: "Le modèle de projet a été enregistré avec succès.",
            life: 5000,
          });
          saveAll.value = false;
          if (id != 'notSet') {
            router.push({ path: "/modeles_projet" });
          } else {
            router.push({ path: "/modele_projet/" + modele_projet.value!.id });
          }
        });
    };

    const deleteModeleProjet = () => {
      confirm.require({
        message: "Etes vous sûr de vouloir supprimer ce modèle de projet ?",
        header: "Suppression",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          apiClient.modeles_projet
            .deleteModeleProjet(modele_projet.value!)
            .then((data: ModeleProjet) => {
              modele_projet.value = data;
              toast.add({
                severity: "success",
                summary: "Suppression",
                detail: "Le modèle de projet a été supprimé.",
                life: 5000,
              });
              router.push({ path: "/modeles_projet" });
            });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    };

    return {
      modele_projet,
      saveModeleProjet,
      deleteModeleProjet,
      newEtape,
      appUser,
      can: usersStore.can,
      products,
      saveAll,
    };
  },
});
