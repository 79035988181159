<template>
  <Toast position="top-right" />
  <slot />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PageLayout",
})
</script>

<style scoped>

</style>