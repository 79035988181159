import ModeleProjetEtape from '@/models/modele_projet_etape/Modele_projet_etape'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'

export interface ModelesProjetEtapeApiClientInterface {
    getModeleProjetEtape: (modele_projet_etape: ModeleProjetEtape) => Promise<ModeleProjetEtape>
    sendModeleProjetEtape: (modele_projet_etape: ModeleProjetEtape) => Promise<ModeleProjetEtape>
    deleteModeleProjetEtape: (modele_projet_etape: ModeleProjetEtape) => Promise<ModeleProjetEtape>
}

export interface ModelesProjetEtapeApiClientUrlsInterface {
    getModeleProjetEtape: string
    postModeleProjetEtape: string
    putModeleProjetEtape: string
    deleteModeleProjetEtape: string
}

export class ModelesProjetEtapeApiClientModel implements ModelesProjetEtapeApiClientInterface {
    private readonly urls!: ModelesProjetEtapeApiClientUrlsInterface
    constructor(urls: ModelesProjetEtapeApiClientUrlsInterface) {
        this.urls = urls
    }

    getModeleProjetEtape(modele_projet_etape: ModeleProjetEtape): Promise<ModeleProjetEtape> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getModeleProjetEtape.replace('$(modele_projet_etapeId)', modele_projet_etape.id!),
            requiresToken: false
        }
        return HttpClient.get<ModeleProjetEtape>(getParameters)
    }

    sendModeleProjetEtape(modele_projet_etape: ModeleProjetEtape): Promise<ModeleProjetEtape> {
        if (modele_projet_etape.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putModeleProjetEtape.replace('$(modele_projet_etapeId)', modele_projet_etape.id!),
                requiresToken: false,
                payload: modele_projet_etape,
            }
            return HttpClient.put<ModeleProjetEtape>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
            url: this.urls.postModeleProjetEtape.replace('$(modele_projetId)', modele_projet_etape.modeleProjet!),
            requiresToken: false,
            payload: modele_projet_etape,
            }
            return HttpClient.post<ModeleProjetEtape>(getParameters)
        }
    }
    deleteModeleProjetEtape(modele_projet_etape: ModeleProjetEtape): Promise<ModeleProjetEtape> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteModeleProjetEtape.replace('$(modele_projet_etapeId)', modele_projet_etape.id!),
            requiresToken: false
        }
        return HttpClient.delete<ModeleProjetEtape>(getParameters)
    }
}
