import Etape from "../etapes/Etape";
import ModeleProjet from "../modele_projet/Modele_projet"
import { UtilisateurInterface } from "../utilisateurs/Utilisateur.interface"

export default class ModeleProjetEtape {
  id: string;
  modeleProjet?: string;
  noOrdre?: number;
  dureeJours?: number;
  etape?: string;
  userDefault?: string;

  constructor(){
      this.id = 'notSet';
      this.dureeJours = 1;
      this.noOrdre = 0;
  }
}