<template>
  <div class="app-info" v-if="can('exec_modedebug')">
    <Card>
      <template #content>
        <div>
          Application :
          <span class="p-text-bold" :style="appStyle">{{ appEnvName }}</span>
        </div>
        <div>
          Serveur API :
          <span class="p-text-bold" :style="apiStyle">{{ appUser.environement }}</span >
        </div>
        <div>
          Base de données : <span class="p-text-bold" :style="dbStyle">{{ appUser.database }}</span>
        </div>
        <hr>
        <div class="p-grid">
          <div class="p-col p-text-bold"><font-awesome-icon icon="bug" />Mode Debug</div>
          <div class="p-col-4">
            <InputSwitch v-model="appUser.is_mode_debug" />
          </div>
        </div>
        <hr>
        <div class="p-grid">
          <div class="p-col" style="color:#cc0000">Production</div>
          <div class="p-col-4">
            <a href="https://spiritv2.espritcom.fr" target="_blank"><i class="pi pi-desktop  environement-icon"></i></a> 
            <a href="https://spiritv2api.espritcom.fr/admin" target="_blank"><i class="pi pi-cog environement-icon"></i></a>
          </div>
        </div>
        <div class="p-grid" style="color:var(--green-400)">
          <div class="p-col">Test</div>
          <div class="p-col-4">
            <a href="https://testspiritv2.espritcom.fr" target="_blank"><i class="pi pi-desktop  environement-icon"></i></a> 
            <a href="https://testspiritv2api.espritcom.fr/admin" target="_blank"><i class="pi pi-cog environement-icon"></i></a>
          </div>
        </div>
        <div class="p-grid" style="color:var(--purple-400)">
          <div class="p-col">Développement</div>
          <div class="p-col-4">
            <a href="http://localhost:8080/" target="_blank"><i class="pi pi-desktop  environement-icon"></i></a> 
            <a href="http://localhost:8000/admin" target="_blank"><i class="pi pi-cog environement-icon"></i></a>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "vue";
import Card from "primevue/card";
import { useUsersStore } from "@/store/users";
import InputSwitch from 'primevue/inputswitch';


export default defineComponent({
  components: {
    Card,
    InputSwitch,
  },
  props: {
    leadId: {
      type: String,
    },
  },
  setup() {
    const usersStore = useUsersStore();
    const appEnvName = computed(() => {
      return process.env.VUE_APP_ENV;
    });

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    const getColor = (environement: string) => {
      switch(environement) {
        case 'developpement':
          return "var(--purple-400)";
        case 'test':
          return "var(--green-400)";
        case 'production':
          return "#cc0000";
      }
    }

    const appStyle = computed(() => {
      return {
        color: getColor(process.env.VUE_APP_ENV),
      };
    });

    const apiStyle = computed(() => {
      return {
        color: getColor(usersStore.state.appUser.environement),
      };
    });

    const dbStyle = computed(() => {
      return {
        color: getColor(usersStore.state.appUser.database),
      };
    });

    const modeDebug = ref(false);

    return {
      appStyle,
      apiStyle,
      appEnvName,
      appUser,
      dbStyle,
      modeDebug,
      can: usersStore.can,
    };
  },
});
</script>

<style lang="scss">
.app-info {
  margin-top: 10px;
  .p-grid {
    margin: 0;
  }
}
.p-card .p-card-content {
  padding: 0;
}

.environement-icon {
  margin: 0 5px;
  color: var(--surface-500)
}
</style>
