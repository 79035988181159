<template>
  <div class="modeles-projet-page">
    <Card>
      <template #title>
        <div class="p-grid">
          <div class="p-col">
            <router-link to="/modele_projet/notSet">
              <Button
                label="Ajouter"
                icon="pi pi-plus"
                iconPos="right"
                class="p-button-success"
              />
            </router-link>
          </div>
          <div class="p-col p-text-right">
            <router-link
              :to="{
                name: 'AuditsPage',
                params: { table: 'spirit_spirit_modeleprojet', id: 'all' },
              }"
            >
              <Button
                label="Audit"
                icon="pi pi-file-excel"
                iconPos="right"
                class="p-button-help"
              />
            </router-link>
          </div>
        </div>
      </template>
      <template #content>
        <br />
        <DataTable :value="modeles_projet">
          <Column
            field="id"
            :class="{ hide: !appUser.is_mode_debug || !can('exec_modedebug') }"
            :sortable="true"
            :reorderableColumn="false"
          >
            <template #header>
              <font-awesome-icon icon="key" />
            </template>
          </Column>
          <Column field="name" header="Modèle de projet"></Column>
          <Column header="Articles concernés">
            <template #body="slotProps">
                <div  v-for="product in slotProps.data.products" :key="product.id">
                  {{product.product_tmpl.name}}
                </div>
            </template>
          </Column>
          <Column field="dureeJours" header="Durée estimée en jours"></Column>
          <Column header="Fiche modèle de projet">
            <template #body="slotProps">
              <router-link :to="'/modele_projet/' + slotProps.data.id">
                <Button icon="pi pi-id-card" />
              </router-link>
            </template>
          </Column>
          <template #footer>
            Il y a {{modeles_projet ? modeles_projet.length : 0}} modèles de projet au total
          </template>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount } from "vue";
import { useUsersStore } from "@/store/users";
import { MutationType } from "@/models/store";
import ModeleProjet from "@/models/modele_projet/Modele_projet";
import Product from "@/models/product/Product";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tag from "primevue/tag";
import Button from "primevue/button";
import Card from "primevue/card";
import apiClient from "@/api-client";
//import Chip from 'primevue/chip';

export default defineComponent({
  name: "EtapesPage",
  components: {
    DataTable,
    Column,
    Button,
    Card,
    //Chip,
  },
  setup() {
    const usersStore = useUsersStore();
    const modeles_projet = ref<ModeleProjet[]>()
    const products = ref<Product[]>();

    apiClient.product
        .getProducts()
        .then((data: Product[]) => {
          products.value = data.map((product) => Object.assign(new Product(), product));
        });

    onBeforeMount(() => {
      apiClient.modeles_projet
        .getModelesProjet()
        .then((data: ModeleProjet[]) => {
          modeles_projet.value = data.map((modele_projet) => Object.assign(new ModeleProjet(), modele_projet));
        });
    });

    const onSelectModeleProjet = (modeles_projet: ModeleProjet) => {
      console.log('ok')
    };

    return {
      modeles_projet,
      onSelectModeleProjet,
      can: usersStore.can,
      appUser: usersStore.state.appUser,
      products,
    };
  },
});
</script>