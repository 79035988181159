import { ModelesProjetApiClientModel, ModelesProjetApiClientInterface, ModelesProjetApiClientUrlsInterface } from '@/models/api-client/modeles_projet'

const urls: ModelesProjetApiClientUrlsInterface = {
    getModelesProjet: process.env.VUE_APP_API_URL + '/api/modele_projets',
    getModeleProjet: process.env.VUE_APP_API_URL + '/api/modele_projets/$(modele_projetId)',
    postModeleProjet: process.env.VUE_APP_API_URL + '/api/modele_projets',
    putModeleProjet: process.env.VUE_APP_API_URL + '/api/modele_projets/$(modele_projetId)',
    deleteModeleProjet: process.env.VUE_APP_API_URL + '/api/modele_projets/$(modele_projetId)'
}

const modeles_projetApiClient: ModelesProjetApiClientInterface = new ModelesProjetApiClientModel(urls)
// export our instance
export default modeles_projetApiClient
