<template>
  <div class="audit-detail">
    <Audit :auditId="auditId"/>
  </div>
</template>

<script lang="ts">
import Audit from "@/components/audits/Audit.vue";
import { defineComponent } from "vue";
import { useRoute } from 'vue-router'

export default defineComponent({
  name: "AuditDetailPage",
  components: {
    Audit,
  },
  setup() {
    const route = useRoute();
    const auditId = route.params.id =='null' ? null : route.params.id;
    
    return {
      auditId
    };
  },
});
</script>