
import { defineComponent, ref } from "vue";
import Card from "primevue/card";
import FullCalendar from "primevue/fullcalendar";
import "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

export default defineComponent({
  name: "Home",
  components: {
    Card,
    FullCalendar,
  },
  setup() {
    const options = ref({
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialDate: "2021-05-01",
      headerToolbar: {
        left: "prev,next",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      },
      editable: true,
    });
    const events = ref<any[]>();

    events.value = [
      {
        id: 1,
        title: "Chantier AZERTY",
        start: "2021-05-04",
      },
      {
        id: 2,
        title: "Chantier SDFG",
        start: "2021-05-09",
      },
      {
        id: 3,
        title: "Chantier PLOP",
        start: "2021-05-19",
      },
    ];

    return { options, events };
  },
});
