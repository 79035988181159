
import { defineComponent, ref, computed } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import Etape from "@/models/etapes/Etape";
import apiClient from "@/api-client";
import router from "@/router";
import Card from "primevue/card";
import { useUsersStore } from "@/store/users";
import InputSwitch from 'primevue/inputswitch';
import ColorPicker from 'primevue/colorpicker';

export default defineComponent({
  components: {
    InputText,
    Button,
    Card,
    InputSwitch,
    ColorPicker,
  },
  props: {
    etapeId: {
      type: String,
    },
  },
  setup(props) {
    const toast = useToast();
    const confirm = useConfirm();
    const etape = ref<Etape>();
    const usersStore = useUsersStore();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });


    etape.value = new Etape();
    etape.value.id = props.etapeId!;

    if (props.etapeId != 'notSet') {
      apiClient.etapes
        .getEtape(etape.value!)
        .then((data: Etape) => {
          etape.value = Object.assign(new Etape(),data);
        });
    }

    const saveEtape = () => {
      apiClient.etapes
        .sendEtape(etape.value!)
        .then((data: Etape) => {
          etape.value = data;
          toast.add({
            severity: "success",
            summary: "Enregistré",
            detail: "L'étape a été enregistrée avec succès.",
            life: 5000,
          });
          router.push({ path: "/etapes" });
        });
    };

    const deleteEtape = () => {
      confirm.require({
        message: "Etes vous sûr de vouloir supprimer cette étape ?",
        header: "Suppression",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          apiClient.etapes
            .deleteEtape(etape.value!)
            .then((data: Etape) => {
              etape.value = data;
              toast.add({
                severity: "success",
                summary: "Suppression",
                detail: "L'étape a été supprimée.",
                life: 5000,
              });
              router.push({ path: "/etapes" });
            });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    };

    return {
      etape,
      saveEtape,
      deleteEtape,
      appUser,
      can: usersStore.can,
    };
  },
});
