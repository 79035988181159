import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { rootStore } from './store';
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primeflex/primeflex.css';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import AppLayout from './layouts/AppLayout.vue';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPaw, faEdit, faBug, faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// icons fontawesome disponibles sur l'app
library.add(faPaw, faEdit, faBug, faKey)

createApp(App)
  .use(router)
  .use(rootStore)
  .use(PrimeVue)
  .use(ToastService)
  .use(ConfirmationService)
  .component('Toast',Toast)
  .component('ConfirmDialog',ConfirmDialog)
  .component('AppLayout', AppLayout)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount('#app')