<template>
  <div class="audit">
    <Card>
      <template #title> </template>

      <template #content>
        <div class="p-grid" v-if="appUser.is_mode_debug && can('exec_modedebug')">
          <div class="p-col"><font-awesome-icon icon="key" /></div>
          <div class="p-col">
            {{ audit.id }}
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col">Date :</div>
          <div class="p-col">
            {{ moment(audit.date_action).format("DD/MM/YYYY HH:mm") }}
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col">Utilisateur :</div>
          <div class="p-col">
            {{ audit.user.email }}
            <span class="p-text-bold" v-if="audit.real_user"> | Utilisateur réel : {{ audit.real_user.email }}</span>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col">Action :</div>
          <div class="p-col">
            <div v-if="audit.action == 'POST'">
              Création
            </div>
            <div v-if="audit.action == 'PUT'">
              Modification
            </div>
            <div v-if="audit.action == 'DELETE'">
              Suppression
            </div>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col">Interface :</div>
          <div class="p-col">{{ audit.interface }}</div>
        </div>
        <div class="p-grid">
          <div class="p-col">Table :</div>
          <div class="p-col">{{ audit.table }}</div>
        </div>
        <div class="p-grid">
          <div class="p-col">Id :</div>
          <div class="p-col">{{ audit.entite_id }}</div>
        </div>
        <div class="p-grid">
          <div class="p-col">Entité :</div>
          <div class="p-col">{{ audit.entite_description }}</div>
        </div>
        <div class="p-grid">
          <div class="p-col">Description :</div>
          <div class="p-col">{{ audit.description }}</div>
        </div>
        <hr />
        <div class="p-grid">
          <div class="p-col">
            <h3>Anciennes valeurs</h3>
          </div>
          <div class="p-col">
            <h3>Nouvelles valeurs</h3>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col">
            <vue-json-pretty :data="audit.values_old"></vue-json-pretty>
          </div>
          <div class="p-col">
            <vue-json-pretty :data="audit.values_new"></vue-json-pretty>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import Card from "primevue/card";
import { AuditInterface } from "@/models/audits/Audit.interface";
import moment from "moment";
import apiClient from "@/api-client";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { useUsersStore } from "@/store/users";

export default defineComponent({
  components: {
    Card,
    VueJsonPretty,
  },
  props: {
    auditId: {
      type: String,
    },
  },
  setup(props) {
    const audit = ref<AuditInterface>();
    const usersStore = useUsersStore();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    audit.value = {
      id: props.auditId!,
      user: {
        id: "",
        email: "",
        nom: "",
        prenom: "",
      },
      real_user: {
        id: "",
        email: "",
        nom: "",
        prenom: "",
      },
      date_action: 0,
      action: "",
      interface: "",
      table: "",
      entite_id: "",
      entite_description: "",
      values_old: "",
      values_new: "",
      description: "",
    };

    apiClient.audits.getAudit(audit.value!).then((data: AuditInterface) => {
      audit.value = data;
      audit.value.values_old = JSON.parse(data.values_old);
      audit.value.values_new = JSON.parse(data.values_new);
    });

    return {
      moment,
      audit,
      appUser,
      can: usersStore.can,
    };
  },
});
</script>

<style lang="scss">
</style>
