import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'
import { AuditInterface } from '@/models/audits/Audit.interface'

export interface AuditsApiClientInterface {
    getAudits: (filters: any) => Promise<AuditInterface[]>
    getAudit: (audit: AuditInterface) => Promise<AuditInterface>
}

export interface AuditsApiClientUrlsInterface {
  getAudits: string
  getAudit: string
}

export class AuditsApiClientModel implements AuditsApiClientInterface {
    private readonly urls!: AuditsApiClientUrlsInterface
    constructor(urls: AuditsApiClientUrlsInterface) {
        this.urls = urls
    }

    getAudits(filters: any): Promise<AuditInterface[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getAudits,
            requiresToken: false,
            filters: filters
        }
        return HttpClient.get<AuditInterface[]>(getParameters)
    }
    getAudit(audit: AuditInterface): Promise<AuditInterface> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getAudit.replace('$(auditId)', audit.id),
            requiresToken: false
        }
        return HttpClient.get<AuditInterface>(getParameters)
    }
}
