<template>
  <div class="simulation-utilisateurs-page">
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useUsersStore } from "@/store/users";
import { MutationType } from "@/models/store";
import { useRoute } from 'vue-router'

export default defineComponent({
  name: "SimulationUtilisateursPage",
  components: {
  },
  setup() {
    const usersStore = useUsersStore();
    const route = useRoute();
    const userId = route.params.userId =='null' ? null : route.params.userId;

    const simuler = (userId: string) => {
      usersStore.action(MutationType.users.simulateUser, {
        userId: userId
      });
    };

    onMounted(() => {
      simuler(userId as string);
    });

    return {
    };
  },
});
</script>

<style lang="scss">

</style>
