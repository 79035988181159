<template>
  <div class="utilisateur-detail">
    <UtilisateurForm :utilisateurId="utilisateurId" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRoute } from 'vue-router'
import UtilisateurForm from '@/components/utilisateurs/UtilisateurForm.vue'

export default defineComponent({
  name: "UtilisateursDetailPage",
  components: {
    UtilisateurForm
  },
  setup() {
    const route = useRoute();
    const utilisateurId = route.params.id =='null' ? null : route.params.id;

    return {
      utilisateurId
    };
  },
});
</script>