import Etape from '@/models/etapes/Etape'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'

export interface EtapesApiClientInterface {
    getEtapes: () => Promise<Etape[]>
    getEtape: (etape: Etape) => Promise<Etape>
    sendEtape: (etape: Etape) => Promise<Etape>
    deleteEtape: (etape: Etape) => Promise<Etape>
}

export interface EtapesApiClientUrlsInterface {
    getEtapes: string
    getEtape: string
    postEtape: string
    putEtape: string
    deleteEtape: string
}

/**
* @Name EtapesApiClientModel
* @description
* Implements the EtapesApiClientInterface interface
*/
export class EtapesApiClientModel implements EtapesApiClientInterface {
    private readonly urls!: EtapesApiClientUrlsInterface
    constructor(urls: EtapesApiClientUrlsInterface) {
        this.urls = urls
    }

    getEtapes(): Promise<Etape[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getEtapes,
            requiresToken: false
        }
        return HttpClient.get<Etape[]>(getParameters)
    }
    getEtape(etape: Etape): Promise<Etape> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getEtape.replace('$(etapeId)', etape.id!),
            requiresToken: false
        }
        return HttpClient.get<Etape>(getParameters)
    }
    sendEtape(etape: Etape): Promise<Etape> {
        if (etape.id != 'notSet') {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putEtape.replace('$(etapeId)', etape.id!),
                requiresToken: false,
                payload: etape,
            }
            return HttpClient.put<Etape>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postEtape,
                requiresToken: false,
                payload: etape,
            }
            return HttpClient.post<Etape>(getParameters)
        }
    }
    deleteEtape(etape: Etape): Promise<Etape> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteEtape.replace('$(etapeId)', etape.id!),
            requiresToken: false
        }
        return HttpClient.delete<Etape>(getParameters)
    }
}
