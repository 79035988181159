
import { defineComponent, watch } from "vue"
import { useErrorStore } from "@/store/error";
import { useToast } from "primevue/usetoast";

export default defineComponent({
  name: "App",
  setup() {
    const errorStore = useErrorStore();
    const toast = useToast();

    watch(
      () => errorStore.state.error,
      () => {
        toast.add({
          severity:'error',
          summary: 'Erreur du serveur',
          detail: errorStore.state.error?.request_status ? 'Erreur : ' + errorStore.state.error?.request_status : 'Seveur hors ligne',
          life: 3000});
      }
    );
  }
});
