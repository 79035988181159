<template>
  <div class="login-body">
    <div class="body-container">
      <div class="p-grid">
        <div class="p-col-12 p-lg-6 left-side">
          <img
            :src="'assets/layout/images/logo.gif'"
            alt="spirit"
          />
          <h2>Bienvenue dans la gestion de projets clients</h2>
          <p>Connectez-vous pour démarrer votre session</p>
        </div>
        <div class="p-col-12 p-lg-6 right-side">
          <div class="login-wrapper">
            <div class="login-container">
              <span class="title">Login</span>

              <div class="p-grid p-fluid">
                <div class="p-col-12">
                  <InputText
                    id="username"
                    placeholder="Username"
                    type="email"
                    v-model="username"
                    @keyup.enter="onClick"
                  />
                </div>
                <div class="p-col-12">
                  <InputText
                    id="password"
                    placeholder="Password"
                    type="password"
                    v-model="password"
                    @keyup.enter="onClick"
                  />
                </div>
                <div class="p-col-6">
                  <Button label="Sign In" icon="pi pi-check" @click="onClick" />
                </div>
                <div class="p-col-6 password-container">
                  <button @click="resetPassword" class="p-link">
                    Forgot Password?
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";
import { useUsersStore } from "@/store/users";
import { MutationType } from "@/models/store";
import { useToast } from "primevue/usetoast";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import apiClient from "@/api-client";

export default defineComponent({
  name: "Login",
  components: {
    InputText,
    Button,
  },
  setup() {
    const username = ref("");
    const password = ref("");
    const usersStore = useUsersStore();
    const toast = useToast();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    const loading = computed(() => {
      return usersStore.state.loading;
    });

    const onClick = () => {
      usersStore.action(MutationType.users.loadAppUser, {
        username: username.value,
        password: password.value,
      });
    };

    const resetPassword = () => {
      if(!username.value) {
        toast.add({
          severity: "warn",
          summary: "Adresse mail manquante",
          detail: "Vous devez renseigner votre adresse mail pour recevoir les instructions.",
          life: 5000,
        });

        return;
      }

      apiClient.users.resetPassword(username.value).then(() => {
        toast.add({
          severity: "info",
          summary: "Réinitialisation de votre mot de passe",
          detail: "Un email vient de vous être envoyé pour chnager votre mot de passe, merci de suivre les instructions.",
          life: 5000,
        });
      });
    };

    return {
      appUser,
      loading,
      username,
      password,
      onClick,
      resetPassword,
    };
  },
});
</script>