import {
    UtilisateursApiClientUrlsInterface,
    UtilisateursApiClientInterface,
    UtilisateursApiClientModel
} from '@/api-client/utilisateurs'

const urls: UtilisateursApiClientUrlsInterface = {
    fetchUtilisateurs: process.env.VUE_APP_API_URL + '/api/users',
    fetchUtilisateur: process.env.VUE_APP_API_URL + '/api/users/$(utilisateurId)',
    postUtilisateur: process.env.VUE_APP_API_URL + '/api/users',
    putUtilisateur: process.env.VUE_APP_API_URL + '/api/users/$(utilisateurId)',
    deleteUtilisateur: process.env.VUE_APP_API_URL + '/api/users/$(utilisateurId)'
}

// instantiate the UtilisateursApiClient pointing at the url that returns static json mock \data
const utilisateursApiClient: UtilisateursApiClientInterface = new UtilisateursApiClientModel(urls)

// export our instance
export default utilisateursApiClient
