<template>
  <div class="app-user-simulation-info" :class="{ 'app-user-simulation-info-active': user.real_user }">
  <div class="info-bar">
    Attention vous simulez un autre utilisateur
  </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useUsersStore } from "@/store/users";

export default defineComponent({
  components: {
  },
  props: {
  },
  setup() {
    const usersStore = useUsersStore();
    const user = computed(() => {
      return usersStore.state.appUser;
    });

    return {
      user,
    };
  },
});
</script>

<style lang="scss">
.app-user-simulation-info-active {
  display: block !important;
}
.app-user-simulation-info {
  height: 34px;
  display: none;

  .info-bar {
    background-color: #dc3545;
    height: 34px;
    position: fixed;
    width: 100%;
    z-index: 999999;
    text-align: center;
    font-size: 24px;
    color: #FFF;
    line-height: 34px;
    
  }
}
</style>
