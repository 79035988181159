
import { defineComponent, ref, computed, watch} from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import ModeleProjetEtape from "@/models/modele_projet_etape/Modele_projet_etape";
import ModeleProjet from "@/models/modele_projet/Modele_projet";
import Etape from "@/models/etapes/Etape";
import { UtilisateurInterface } from "@/models/utilisateurs/Utilisateur.interface";
import apiClient from "@/api-client";
import { useUsersStore } from "@/store/users";
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';

export default defineComponent({
  components: {
    InputText,
    Button,
    Dropdown,
    InputSwitch,
  },
  props: {
    etapeModeleId: {
      type: String,
    },
    bSave: {
      type: Boolean,
    },
    modele_projet: {
      type: ModeleProjet,
    },
    idx: {
      type: Number,
    }
  },
  setup(props) {
    const toast = useToast();
    const confirm = useConfirm();
    const modele_projet_etape = ref<ModeleProjetEtape>();
    modele_projet_etape.value = new ModeleProjetEtape();
    modele_projet_etape.value.id = props.etapeModeleId!;
    const usersStore = useUsersStore();
    const etapes = ref<Etape[]>();
    etapes.value = [];
    const utilisateurs = ref<UtilisateurInterface[]>();
    const selectedEtape = ref<Etape>();
    selectedEtape.value = new Etape();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    const saveModeleProjetEtape = () => {
      apiClient.modeles_projet_etape
          .sendModeleProjetEtape(modele_projet_etape.value!)
          .then((data: ModeleProjetEtape) => {
          modele_projet_etape.value = data;
        });
    };

    apiClient.etapes
      .getEtapes()
      .then((data: Etape[]) => {
        etapes.value = data.map((etape) => Object.assign(new Etape(), etape));
        if (props.etapeModeleId != 'notSet') {
          apiClient.modeles_projet_etape
            .getModeleProjetEtape(modele_projet_etape.value!)
            .then((data: ModeleProjetEtape) => {
              modele_projet_etape.value = Object.assign(new ModeleProjetEtape(),data);
              selectedEtape.value = etapes.value!.filter(elem => elem.id == modele_projet_etape.value!.etape)[0];
            });
        }
        else {
          modele_projet_etape.value!.modeleProjet = props.modele_projet!.id!;
          selectedEtape.value = etapes.value![0];
          modele_projet_etape.value!.etape = selectedEtape.value.id;
          modele_projet_etape.value!.noOrdre = props.modele_projet!.etapesModele!.length
        }
    });

    apiClient.utilisateurs
      .fetchUtilisateurs()
      .then((data: UtilisateurInterface[]) => {
        utilisateurs.value = data;
      });

      watch(() => props.bSave, (bSave) => {
        if(bSave) {
          saveModeleProjetEtape();
        }
      });

    watch(() => modele_projet_etape.value!.etape, (etapeId) => {
        selectedEtape.value = etapes.value!.filter(elem => elem.id == etapeId)[0];
    });


    const deleteModeleProjetEtape = () => {
      confirm.require({
        message: "Etes vous sûr de vouloir supprimer cette étape ?",
        header: "Suppression",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          if (modele_projet_etape.value!.id != 'notSet') {
            apiClient.modeles_projet_etape
              .deleteModeleProjetEtape(modele_projet_etape.value!)
              .then((data: ModeleProjetEtape) => {
                modele_projet_etape.value = data;
                toast.add({
                  severity: "success",
                  summary: "Suppression",
                  detail: "L'étape a été supprimée.",
                  life: 5000,
                });
                props.modele_projet!.etapesModele!.splice(props.idx!, 1);
              });
          } else {
            props.modele_projet!.etapesModele!.splice(props.idx!, 1);
          }
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    };

    return {
      modele_projet_etape,
      deleteModeleProjetEtape,
      appUser,
      can: usersStore.can,
      etapes,
      utilisateurs,
      selectedEtape,
    };
  },
});
