
import { defineComponent, ref, onMounted, watch } from "vue";
import { AuditInterface } from "@/models/audits/Audit.interface";
import apiClient from "@/api-client";
import AuditsList from "@/components/audits/AuditsList.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "AuditsPage",
  components: {
    AuditsList,
  },
  setup() {
    const audits = ref<AuditInterface[]>();
    const route = useRoute();
    let filters: any;
    if (route.params.id != "all") {
      filters = { entite_id: route.params.id };
    } else {
      filters = { table: route.params.table };
    }

    onMounted(() => {
      apiClient.audits.getAudits(filters).then((data: AuditInterface[]) => {
        audits.value = data;
      });
    });

    watch(
      () => route.params,
      () => {
        apiClient.audits.getAudits({}).then((data: AuditInterface[]) => {
          audits.value = data;
        });
      }
    );

    return {
      audits,
      filters,
    };
  },
});
