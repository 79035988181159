
import { defineComponent, ref, computed } from "vue";
import Card from "primevue/card";
import { AuditInterface } from "@/models/audits/Audit.interface";
import moment from "moment";
import apiClient from "@/api-client";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { useUsersStore } from "@/store/users";

export default defineComponent({
  components: {
    Card,
    VueJsonPretty,
  },
  props: {
    auditId: {
      type: String,
    },
  },
  setup(props) {
    const audit = ref<AuditInterface>();
    const usersStore = useUsersStore();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    audit.value = {
      id: props.auditId!,
      user: {
        id: "",
        email: "",
        nom: "",
        prenom: "",
      },
      real_user: {
        id: "",
        email: "",
        nom: "",
        prenom: "",
      },
      date_action: 0,
      action: "",
      interface: "",
      table: "",
      entite_id: "",
      entite_description: "",
      values_old: "",
      values_new: "",
      description: "",
    };

    apiClient.audits.getAudit(audit.value!).then((data: AuditInterface) => {
      audit.value = data;
      audit.value.values_old = JSON.parse(data.values_old);
      audit.value.values_new = JSON.parse(data.values_new);
    });

    return {
      moment,
      audit,
      appUser,
      can: usersStore.can,
    };
  },
});
