
import { defineComponent, ref} from "vue";
import { ActionInterface } from "@/models/actions/Action.interface";
import { useToast } from "primevue/usetoast";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useRoute } from 'vue-router'
import apiClient from "@/api-client";
import router from "@/router";

export default defineComponent({
  name: "Start",
  components: {
    InputText,
    Button,
  },
  setup() {
    const route = useRoute();
    const toast = useToast();
    const password = ref('');

    const changePassword = () => {
      if(!password.value) {
        toast.add({
          severity: "warn",
          summary: "Mot de passe manquant",
          detail: "Vous devez spécifier un mot de passe",
          life: 5000,
        });

        return;
      }

      const action: ActionInterface = {
        id: '' + route.params.id,
        data: password.value,
        name: 'UserInvnitation'
      }

      apiClient.users.changePassword(action).then(() => {
        toast.add({
          severity: "info",
          summary: "OK !",
          detail: "Le mot de passe a été correctement enregistré.",
          life: 5000,
        });
        router.push('/login');
      });
    };

    return {
      password,
      changePassword,
    };
  },
});
