
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import Button from "primevue/button";

export default defineComponent({
  name: "Start",
  components: {
    Button,
  },
  setup() {
    const route = useRoute();
    const password = ref("");

    return {};
  },
});
