import {
    AuditsApiClientUrlsInterface,
    AuditsApiClientInterface,
    AuditsApiClientModel
} from '@/api-client/audits'
const urls: AuditsApiClientUrlsInterface = {
    getAudits: process.env.VUE_APP_API_URL + '/api/audits',
    getAudit: process.env.VUE_APP_API_URL + '/api/audits/$(auditId)',
}
// instantiate the AuditsApiClient pointing at the url that returns static json mock \data
const auditsApiClient: AuditsApiClientInterface = new AuditsApiClientModel(urls)
// export our instance
export default auditsApiClient
