
import { defineComponent } from "vue";
import { useRoute } from 'vue-router'
import EtapeForm from '@/components/etapes/EtapeForm.vue'

export default defineComponent({
  name: "EtapeDetailPage",
  components: {
    EtapeForm
  },
  setup() {
    const route = useRoute();
    const etapeId = route.params.id =='null' ? null : route.params.id;

    return {
      etapeId
    };
  },
});
