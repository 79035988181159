import { Module, MutationTree, ActionTree, GetterTree } from 'vuex'
import { MutationType, RootStateInterface,  ErrorStateInterface } from '@/models/store'
import { initialErrorState } from './initialState'
import { ErrorInterface } from '@/models/error/Error.interface'

export const mutations: MutationTree<ErrorStateInterface> = {
    writedError(state: ErrorStateInterface, params) {
        state.error = params.error
    }
}

export const actions: ActionTree<ErrorStateInterface, RootStateInterface> = {
    writeError({ commit }, params: { error: ErrorInterface }) {
        commit(MutationType.error.writedError, params)
    }
}

export const getters: GetterTree<ErrorStateInterface, RootStateInterface> = {}

const namespaced = true
const state: ErrorStateInterface = initialErrorState
export const errorState: Module<ErrorStateInterface, RootStateInterface> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}