<template>
  <div id="app">
    <AppLayout>
      <router-view />
    </AppLayout>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch } from "vue"
import { useErrorStore } from "@/store/error";
import { useToast } from "primevue/usetoast";

export default defineComponent({
  name: "App",
  setup() {
    const errorStore = useErrorStore();
    const toast = useToast();

    watch(
      () => errorStore.state.error,
      () => {
        toast.add({
          severity:'error',
          summary: 'Erreur du serveur',
          detail: errorStore.state.error?.request_status ? 'Erreur : ' + errorStore.state.error?.request_status : 'Seveur hors ligne',
          life: 3000});
      }
    );
  }
});
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  h2 {
    margin: 0;
  }
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.p-datatable .p-datatable-loading-icon {
    font-size: 5rem !important;
    color:#ffffff;
}

.hide {
  display: none;
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width : 640px) {
  .left-menu {
    background-color: #f8f9fa !important;
    position: absolute !important;
    z-index: 999999 !important;
  }
}
</style>
