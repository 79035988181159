
import { defineComponent, computed } from "vue";
//import Menubar from "primevue/menubar";
import SplitButton from "primevue/splitbutton";
import { MutationType } from "@/models/store";
import router from "@/router";
import { useUsersStore } from "@/store/users";
import Button from "primevue/button";

export default defineComponent({
  name: "DashboardTopbar",
  components: {
    SplitButton,
    Button,
  },
  props: {
    toggleDisplayMenu: Function,
  },
  setup() {
    const usersStore = useUsersStore();
    const user = computed(() => {
      return usersStore.state.appUser;
    });

    const items = null;

    const appUserItems = computed(() => {
      return [
        {
          label: "Se déconnecter",
          icon: "pi pi-sign-out",
          command: () => {
            usersStore.action(MutationType.users.logoutAppUser, {});
            router.push({ path: "/login" });
          },
        },
      ];
    });

    return {
      appUserItems,
      user,
      items,
    };
  },
});
