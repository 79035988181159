<template>
  <div class="modele-projet-detail">
    <ModeleProjetForm :modeleProjetId="modeleProjetId" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRoute } from 'vue-router'
import ModeleProjetForm from '@/components/modele_projet/ModeleProjetForm.vue'

export default defineComponent({
  name: "ModeleProjetDetailPage",
  components: {
    ModeleProjetForm
  },
  setup() {
    const route = useRoute();
    const modeleProjetId = route.params.id =='null' ? null : route.params.id;

    return {
      modeleProjetId
    };
  },
});
</script>