
import { defineComponent, ref, onMounted, computed } from "vue";
import { UtilisateurInterface } from "@/models/utilisateurs/Utilisateur.interface";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import apiClient from "@/api-client";
import Card from "primevue/card";
import { useUsersStore } from "@/store/users";

export default defineComponent({
  name: "UtilisateursPage",
  components: {
    DataTable,
    Column,
    Button,
    Card,
  },
  setup() {
    const utilisateurs = ref<UtilisateurInterface[]>();
    const usersStore = useUsersStore();
    const user = computed(() => {
      return usersStore.state.appUser;
    });

    onMounted(() => {
      apiClient.utilisateurs
        .fetchUtilisateurs()
        .then((data: UtilisateurInterface[]) => {
          utilisateurs.value = data;
        });
    });

    return {
      utilisateurs,
      can: usersStore.can,
      appUser: usersStore.state.appUser,
    };
  },
});
