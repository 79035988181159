<template>
  <div class="utilisateurs-page">
    <Card>
      <template #title>
        <div class="p-grid">
          <div class="p-col"></div>
          <div class="p-col p-text-right">
            <router-link
              :to="{
                name: 'AuditsPage',
                params: { table: 'users_user', id: 'all' },
              }"
            >
              <Button
                label="Audit"
                icon="pi pi-file-excel"
                iconPos="right"
                class="p-button-help"
              />
            </router-link>
          </div>
        </div>
      </template>

      <template #content>
        <DataTable :value="utilisateurs">
          <Column
            field="id"
            :class="{ hide: !appUser.is_mode_debug || !can('exec_modedebug') }"
            :sortable="true"
            :reorderableColumn="false"
          >
            <template #header>
              <font-awesome-icon icon="key" />
            </template>
          </Column>
          <Column header="Actif" style="width: 100px">
            <template #body="slotProps">
              <i
                v-if="slotProps.data.is_active"
                class="pi pi-user color-green"
              ></i>
              <i
                v-if="!slotProps.data.is_active"
                class="pi pi-user color-red"
              ></i>
            </template>
          </Column>
          <Column field="email" header="Adresse mail"></Column>
          <Column header="Nom">
            <template #body="slotProps">
              {{ slotProps.data.last_name }}
            </template>
          </Column>
          <Column header="Prénom">
            <template #body="slotProps">
              {{ slotProps.data.first_name }}
            </template>
          </Column>
          <Column header="Statut">
            <template #body="slotProps">
              <div v-if="slotProps.data.statut == 0">
                Invitation envoyée
              </div>
              <div v-if="slotProps.data.statut == 1">
                <i class="pi pi-check-square"></i>
                Invitation confirmée
              </div>
            </template>
          </Column>
          <Column header="Action">
            <template #body="{ data }">
              <div class="p-text-right">
                <router-link
                  :to="'/simulation-utilisateur/' + data.id"
                  v-if="
                    data.is_active &&
                    can('exec_usersimulation') &&
                    data.id != appUser.id &&
                    appUser.real_user == null
                  "
                >
                  <Button class="p-button-help">
                    <font-awesome-icon icon="paw" />
                  </Button>
                </router-link>
                &nbsp;
                <router-link :to="'/utilisateur/' + data.id">
                  <Button class="p-button-secondary">
                    <font-awesome-icon icon="edit" />
                  </Button>
                </router-link>
              </div>
            </template>
          </Column>
          <template #footer>
            Nombre de lignes affichées : {{ utilisateurs ? utilisateurs.length : 0}}
          </template>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from "vue";
import { UtilisateurInterface } from "@/models/utilisateurs/Utilisateur.interface";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import apiClient from "@/api-client";
import Card from "primevue/card";
import { useUsersStore } from "@/store/users";

export default defineComponent({
  name: "UtilisateursPage",
  components: {
    DataTable,
    Column,
    Button,
    Card,
  },
  setup() {
    const utilisateurs = ref<UtilisateurInterface[]>();
    const usersStore = useUsersStore();
    const user = computed(() => {
      return usersStore.state.appUser;
    });

    onMounted(() => {
      apiClient.utilisateurs
        .fetchUtilisateurs()
        .then((data: UtilisateurInterface[]) => {
          utilisateurs.value = data;
        });
    });

    return {
      utilisateurs,
      can: usersStore.can,
      appUser: usersStore.state.appUser,
    };
  },
});
</script>

<style lang="scss">
.color-green {
  color: green;
}

.color-red {
  color: red;
}
</style>
