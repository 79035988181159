<template>
  <div class="login-body">
    <div class="body-container">
      <div class="p-grid">
        <div class="p-col-12 p-lg-6 left-side">
          <img
            :src="'assets/layout/images/logo.gif'"
            alt="Spirit"
          />
          <h1>Invitation utilisateur</h1>
          <p>Vous avez été invité à devenir un utilisateur de SPIRIT.</p>
          <p>Pour terminer votre inscription et acceder à votre tableau de bord, choisisez votre mot de passe.</p>
        </div>
        <div class="p-col-12 p-lg-6 right-side">
          <div class="login-wrapper">
            <div class="login-container">
              <span class="title">Choisissez votre mot de passe</span>

              <div class="p-grid p-fluid">
                <div class="p-col-12">
                  <InputText
                    id="password"
                    placeholder="Password"
                    type="password"
                    v-model="password"
                  />
                </div>
                <div class="p-col-6">
                  
                </div>
                <div class="p-col-6 password-container">
                  <Button label="Continuer" icon="pi pi-check" @click="changePassword" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref} from "vue";
import { ActionInterface } from "@/models/actions/Action.interface";
import { useToast } from "primevue/usetoast";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useRoute } from 'vue-router'
import apiClient from "@/api-client";
import router from "@/router";

export default defineComponent({
  name: "Start",
  components: {
    InputText,
    Button,
  },
  setup() {
    const route = useRoute();
    const toast = useToast();
    const password = ref('');

    const changePassword = () => {
      if(!password.value) {
        toast.add({
          severity: "warn",
          summary: "Mot de passe manquant",
          detail: "Vous devez spécifier un mot de passe",
          life: 5000,
        });

        return;
      }

      const action: ActionInterface = {
        id: '' + route.params.id,
        data: password.value,
        name: 'UserInvnitation'
      }

      apiClient.users.changePassword(action).then(() => {
        toast.add({
          severity: "info",
          summary: "OK !",
          detail: "Le mot de passe a été correctement enregistré.",
          life: 5000,
        });
        router.push('/login');
      });
    };

    return {
      password,
      changePassword,
    };
  },
});
</script>