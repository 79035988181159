<template>
  <ConfirmDialog></ConfirmDialog>
  <Card>
    <template #content>
      <div class="p-grid">
        <div class="p-col p-text-right">
          <router-link
            :to="{
              name: 'AuditsPage',
              params: { table: 'users_user', id: utilisateur.id },
            }"
          >
            <Button
              label="Audit"
              icon="pi pi-file-excel"
              iconPos="right"
              class="p-button-help"
            />
          </router-link>
        </div>
      </div>
      <div class="p-fluid">
        <div class="p-field p-grid" v-if="appUser.is_mode_debug && can('exec_modedebug')">
          <label for="id" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            <font-awesome-icon icon="key" />
          </label>
          <div class="p-col-12 p-md-10">
            <InputText
              disabled
              v-model="utilisateur.id"
              id="id"
              type="text"
            />
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="email" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            Adresse Mail
          </label>
          <div class="p-col-12 p-md-10">
            <InputText
              disabled
              v-model="utilisateur.email"
              id="email"
              type="text"
            />
          </div>
        </div>
        <div class="p-field p-grid">
          <label for="email" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            Actif
          </label>
          <div class="p-col-12 p-md-10">
            <InputSwitch
              v-model="utilisateur.is_active"
              :disabled="utilisateur.id == appUser.id"
            />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <Button
        @click="saveUtilisateur"
        label="Enregistrer"
        icon="pi pi-save"
        iconPos="right"
      />
    </template>
  </Card>

</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { UtilisateurInterface } from "@/models/utilisateurs/Utilisateur.interface";
import apiClient from "@/api-client";
import Card from "primevue/card";
import InputSwitch from "primevue/inputswitch";
import { useUsersStore } from "@/store/users";

export default defineComponent({
  components: {
    InputText,
    Button,
    Card,
    InputSwitch,
  },
  props: {
    utilisateurId: {
      type: String,
    },
  },
  setup(props) {
    const toast = useToast();
    const utilisateur = ref<UtilisateurInterface>();
    const usersStore = useUsersStore();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    utilisateur.value = {
      id: props.utilisateurId!,
      email: "",
      is_active: true,
    };

    if (props.utilisateurId) {
      apiClient.utilisateurs
        .fetchUtilisateur(utilisateur.value)
        .then((data: UtilisateurInterface) => {
          utilisateur.value = data;
        });
    }

    const saveUtilisateur = () => {
      apiClient.utilisateurs
        .sendUtilisateur(utilisateur.value!)
        .then((data: UtilisateurInterface) => {
          utilisateur.value = data;
          toast.add({
            severity: "success",
            summary: "Enregistré",
            detail: "L'utilisateur a été enregistré avec succès.",
            life: 5000,
          });
        });
    };

    return {
      utilisateur,
      saveUtilisateur,
      appUser,
      can: usersStore.can,
    };
  },
});
</script>

<style lang="scss">
</style>
