// export the MutationType namespace
export * from './mutation-type/MutationType'
// export the StoreModuleNames namespace
export * from './module-names/StoreModuleNames'
// export RooState and RootStore interfaces
export * from './root/RootState.interface'
export * from './root/RootStore.interface'
// export the RootStore model
export * from './root/RootStore.model'
// GEN-EXPORTS
// as you add more state modules, add additional exports for those here as well
export * from './users/UsersState.interface'
export * from './error/ErrorState.interface' 
