<template>
  <div class="etape-form">
    <ConfirmDialog></ConfirmDialog>
    <Card>
      <template #content>
        <div class="p-grid">
          <div class="p-col p-text-left">
            <h3>
              <router-link to="/etapes">
                  <Button class="p-button-outlined p-button-sm" icon="pi pi-arrow-left" />
              </router-link>
              Détail d'une étape
            </h3>
          </div>
          <div class="p-col p-text-right">
            <router-link
              :to="{
                name: 'AuditsPage',
                params: {
                  table: 'spirit_spirit_etape',
                  id: etape.id,
                },
              }"
            >
              <Button
                label="Audit"
                icon="pi pi-file-excel"
                iconPos="right"
                class="p-button-help"
              />
            </router-link>
          </div>
        </div>
        <div class="p-fluid">
          <div class="p-field p-grid" v-if="appUser.is_mode_debug && can('exec_modedebug')">
            <label for="id" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              <font-awesome-icon icon="key" />
            </label>
            <div class="p-col-12 p-md-10">
              <InputText
                disabled
                v-model="etape.id"
                id="id"
                type="text"
              />
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              Etape
            </label>
            <div class="p-col-12 p-md-10">
              <InputText v-model="etape.name" id="name" type="text" />
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="couleur" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              Couleur
            </label>
            <div class="p-col-12 p-md-10">
              <ColorPicker v-model="etape.couleur"/>
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="for_operateur" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              Réalisable par un opérateur
            </label>
            <div class="p-col-12 p-md-10">
              <InputSwitch v-model="etape.for_operateur" />
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="for_referent" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              Réalisable par un référent
            </label>
            <div class="p-col-12 p-md-10">
              <InputSwitch v-model="etape.for_referent" />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <Button
          @click="saveEtape"
          label="Enregistrer"
          icon="pi pi-save"
          iconPos="right"
        />
        <Button
          @click="deleteEtape"
          label="Supprimer"
          class="p-button-danger"
          icon="pi pi-trash"
          iconPos="right"
        />
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import Etape from "@/models/etapes/Etape";
import apiClient from "@/api-client";
import router from "@/router";
import Card from "primevue/card";
import { useUsersStore } from "@/store/users";
import InputSwitch from 'primevue/inputswitch';
import ColorPicker from 'primevue/colorpicker';

export default defineComponent({
  components: {
    InputText,
    Button,
    Card,
    InputSwitch,
    ColorPicker,
  },
  props: {
    etapeId: {
      type: String,
    },
  },
  setup(props) {
    const toast = useToast();
    const confirm = useConfirm();
    const etape = ref<Etape>();
    const usersStore = useUsersStore();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });


    etape.value = new Etape();
    etape.value.id = props.etapeId!;

    if (props.etapeId != 'notSet') {
      apiClient.etapes
        .getEtape(etape.value!)
        .then((data: Etape) => {
          etape.value = Object.assign(new Etape(),data);
        });
    }

    const saveEtape = () => {
      apiClient.etapes
        .sendEtape(etape.value!)
        .then((data: Etape) => {
          etape.value = data;
          toast.add({
            severity: "success",
            summary: "Enregistré",
            detail: "L'étape a été enregistrée avec succès.",
            life: 5000,
          });
          router.push({ path: "/etapes" });
        });
    };

    const deleteEtape = () => {
      confirm.require({
        message: "Etes vous sûr de vouloir supprimer cette étape ?",
        header: "Suppression",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          apiClient.etapes
            .deleteEtape(etape.value!)
            .then((data: Etape) => {
              etape.value = data;
              toast.add({
                severity: "success",
                summary: "Suppression",
                detail: "L'étape a été supprimée.",
                life: 5000,
              });
              router.push({ path: "/etapes" });
            });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    };

    return {
      etape,
      saveEtape,
      deleteEtape,
      appUser,
      can: usersStore.can,
    };
  },
});
</script>

<style lang="scss">
.etape-form {
  margin: 0px;
  .p-card-footer {
    text-align: end;
    .p-button {
      margin: 10px !important;
    }
  }
}

</style>