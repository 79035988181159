<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import PageLayout from "./PageLayout.vue";
import DashboardLayout from "./DashboardLayout.vue";
import { shallowRef, watch } from "vue";
import { useRoute } from "vue-router";
import { useUsersStore } from "@/store/users";
import { MutationType, StoreModuleNames } from "@/models/store";
import router from "@/router";

export default defineComponent({
  name: "AppLayout",
  setup() {
    const layout = shallowRef(PageLayout);
    const route = useRoute();
    const usersStore = useUsersStore();

    const user = computed(() => {
      return usersStore.state.appUser;
    });

    if (!usersStore.state.appUser.token && localStorage.getItem("appUser")) {
      usersStore.action(MutationType.users.loadFromLocalstorageAppUser, {
        appUser: JSON.parse(localStorage.getItem("appUser")!),
      });
    }

    watch(
      () => route.meta,
      async (meta) => {
        if (!user.value.token && meta.layout == "DashboardLayout") {
          router.push("/login");
        }
        
        const permissions: string = typeof meta.permissions == 'string' ? meta.permissions : ''

        if (permissions != '' && !usersStore.can(permissions)) {
          router.push("/forbidden403");
        }

        switch (meta.layout) {
          case "DashboardLayout":
            layout.value = DashboardLayout;
            break;

          case "PageLayout":
            layout.value = PageLayout;
            break;
        }
      },
      { immediate: true }
    );
    return { layout };
  },
});
</script>