import Product from '@/models/product/Product'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'

export interface ProductApiClientInterface {
    getProducts: () => Promise<Product[]>
}

export interface ProductApiClientUrlsInterface {
    getProducts: string
}

export class ProductApiClientModel implements ProductApiClientInterface {
    private readonly urls!: ProductApiClientUrlsInterface
    constructor(urls: ProductApiClientUrlsInterface) {
        this.urls = urls
    }

    getProducts(): Promise<Product[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.getProducts,
            requiresToken: false
        }
        return HttpClient.get<Product[]>(getParameters)
    }
}
