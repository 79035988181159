import { RouteRecordRaw, createRouter, createWebHashHistory } from 'vue-router';
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Start from '../views/Start.vue'
import Forbidden403 from '../views/Forbidden403.vue'
import ResetPassword from '../views/ResetPassword.vue'
import UtilisateursPage from '../views/UtilisateursPage.vue'
import UtilisateurDetailPage from '../views/UtilisateurDetailPage.vue'
import AuditsPage from '../views/AuditsPage.vue'
import AuditDetailPage from '../views/AuditDetailPage.vue'
import SimulationUtilisateursPage from '../views/SimulationUtilisateursPage.vue'

import EtapesPage from '../views/EtapesPage.vue'
import EtapeDetailPage from '../views/EtapeDetailPage.vue'

import ModelesProjetPage from '../views/ModelesProjetPage.vue'
import ModeleProjetDetailPage from '../views/ModeleProjetDetailPage.vue'


const routes: Array<RouteRecordRaw> = [
  // Dashboard spirit
  //-------------------------------------------------------------------------------------------------------------------------------------
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      layout: 'DashboardLayout',
    }
  },
  {
    path: '/audits/:table:/:id',
    alias: ['/audits'],
    name: 'AuditsPage',
    component: AuditsPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'view_audit',
    }
  },
  {
    path: '/audit/:id',
    name: 'AuditDetailPage',
    component: AuditDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'view_audit',
    }
  },
  {
    path: '/utilisateurs',
    name: 'UtilisateursPage',
    component: UtilisateursPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'view_user',
    }
  },
  {
    path: '/utilisateur/:id',
    name: 'UtilisateurDetailPage',
    component: UtilisateurDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'view_user',
    }
  },
  {
    path: '/etapes',
    name: 'EtapesPage',
    component: EtapesPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'view_etape',
    }
  },
  {
    path: '/etape/:id',
    name: 'EtapeDetailPage',
    component: EtapeDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'view_etape',
    }
  },
  {
    path: '/modeles_projet',
    name: 'ModelesProjetPage',
    component: ModelesProjetPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'view_modeleprojet',
    }
  },
  {
    path: '/modele_projet/:id',
    name: 'ModeleProjetDetailPage',
    component: ModeleProjetDetailPage,
    meta: {
      layout: 'DashboardLayout',
      permissions: 'view_modeleprojet',
    }
  },

  // Common
  //-------------------------------------------------------------------------------------------------------------------------------------
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'PageLayout'
    }
  },
  {
    path: '/simulation-utilisateur/:userId',
    name: 'SimulationUtilisateur',
    component: SimulationUtilisateursPage,
    meta: {
      layout: 'PageLayout'
    }
  },
  {
    path: '/start/:id',
    name: 'Start',
    component: Start,
    meta: {
      layout: 'PageLayout'
    }
  },
  {
    path: '/forbidden403',
    name: 'Forbidden403',
    component: Forbidden403,
    meta: {
      layout: 'PageLayout'
    }
  },
  {
    path: '/resetpassword/:id',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      layout: 'PageLayout'
    }
  },
  // Autre
  //-------------------------------------------------------------------------------------------------------------------------------------

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router