<template>
  <div class="audits-list">
    <Card>
      <template #title>
      </template>

      <template #content>
        <DataTable :value="audits">
          <Column header="Date">
            <template #body="slotProps">
              {{ moment(slotProps.data.date_action).format('DD/MM/YYYY HH:mm') }}
            </template>
          </Column>
          <Column header="Action">
            <template #body="slotProps">
              <div v-if="slotProps.data.action == 'POST'">Création</div>
              <div v-if="slotProps.data.action == 'PUT'">Modification</div>
              <div v-if="slotProps.data.action == 'DELETE'">Suppression</div>
            </template>
          </Column>
          <Column field="user.email" header="Utilisateur"></Column>
          <Column field="entite_description" header="Entité"></Column>
          <Column field="entite_id" header="Id"></Column>
          <Column field="interface" header="Interface"></Column>
          <Column field="table" header="Table"></Column> 
          <Column header="détail">
            <template #body="slotProps">
              <router-link :to="'/audit/' + slotProps.data.id">
                <Button icon="pi pi-id-card" />
              </router-link>
            </template>
          </Column>

          <template #footer>
            Nombre de lignes : {{ audits ? audits.length : 0 }}
          </template>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Card from "primevue/card";
import Button from "primevue/button";
import { AuditInterface } from "@/models/audits/Audit.interface";
import moment from 'moment'

export default defineComponent({
  components: {
    Card,
    DataTable,
    Column,
    Button,
  },
  props: {
    audits: {
      type: Array as PropType<AuditInterface[]>
    }
  },
  setup(props) {
    return {
      moment
    };
  },
});
</script>

<style lang="scss">

</style>
