<template class="p-d-none p-d-md-inline-flex">
  <Menu :model="items" style="width: 100%" />
  <AppInfo />
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import Menu from "primevue/menu";
import AppInfo from "@/components/app-info/AppInfo.vue"

export default defineComponent({
  name: "DashboardLeftMenu",
  components: {
    Menu,
    AppInfo,
  },
  setup() {

    const items = computed(() => {
      var odooUrl;
      if (process.env.VUE_APP_ENV=='production') {
        odooUrl = "https://odoo.espritcom.fr"
      } else {
        odooUrl = "https://testodoo.espritcom.fr"
      }
      return [
        {
          label: "Projets Clients",
          items: []
        },
        {
          label: "Paramètres",
          items: [
            {
              label: "Modèles de projet",
              icon: "pi pi-copy",
              to: "/modeles_projet",
            },
            {
              label: "Etapes",
              icon: "pi pi-forward",
              to: "/etapes",
            },
            {
              label: "Utilisateurs",
              icon: "pi pi-user",
              to: "/utilisateurs",
            },
            {
              label: "Audit",
              icon: "pi pi-file-excel",
              to: "/audits",
            },
          ],
        },
        {
          label: "Liens",
          items: [
            {
              label: "Odoo",
              icon: "pi pi-external-link",
              url: odooUrl,
              target: "_blank",
            },
          ],
        },
      ];
    });

    return {
      items
    };
  },
});
</script>

<style lang="scss">
.app-info {
  margin-top: 10px;
}
.p-card .p-card-content {
  padding: 0;
}
</style>