// "id": 35,
// "default_code": "FURN_8999",
// "active": true,
// "product_tmpl": {
//     "id": 27,
//     "name": "Three-Seat Sofa"
// }

export default class Product {
  id?: number
  default_code?: string
  product_tmpl?: any

  get name(): string {
    return this.product_tmpl.name;
  }

  get longName(): string {
    if (this.default_code) {
      return this.product_tmpl.name + ' (' + this.default_code + ')';
    }
    else {
      return this.product_tmpl.name
    }
  }

}