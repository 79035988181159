<template>
  <div class="etapes-page">
    <Card>
      <template #title>
        <div class="p-grid">
          <div class="p-col">
            <router-link to="/etape/notSet">
              <Button
                label="Ajouter"
                icon="pi pi-plus"
                iconPos="right"
                class="p-button-success"
              />
            </router-link>
          </div>
          <div class="p-col p-text-right">
            <router-link
              :to="{
                name: 'AuditsPage',
                params: { table: 'spirit_spirit_etape', id: 'all' },
              }"
            >
              <Button
                label="Audit"
                icon="pi pi-file-excel"
                iconPos="right"
                class="p-button-help"
              />
            </router-link>
          </div>
        </div>
      </template>
      <template #content>
        <br />
        <DataTable :value="etapes">
          <Column
            field="id"
            :class="{ hide: !appUser.is_mode_debug || !can('exec_modedebug') }"
            :sortable="true"
            :reorderableColumn="false"
          >
            <template #header>
              <font-awesome-icon icon="key" />
            </template>
          </Column>
          <Column field="name" header="Etape"></Column>
          <Column header="Couleur">
            <template #body="slotProps">
              <ColorPicker v-model="slotProps.data.couleur" :disabled="true"/>
            </template>
          </Column>
          <Column header="Réalisable par un opérateur">
            <template #body="slotProps">
              <InputSwitch v-model= "slotProps.data.for_operateur" :disabled="true"/>
            </template>
          </Column>
          <Column header="Réalisable par un référent">
            <template #body="slotProps">
              <InputSwitch v-model= "slotProps.data.for_referent" :disabled="true"/>
            </template></Column>
          <Column header="Fiche étape">
            <template #body="slotProps">
              <router-link :to="'/etape/' + slotProps.data.id">
                <Button icon="pi pi-id-card" />
              </router-link>
            </template>
          </Column>
          <template #footer>
            Il y a {{etapes ? etapes.length : 0}} étapes au total
          </template>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount } from "vue";
import { useUsersStore } from "@/store/users";
import { MutationType } from "@/models/store";
import Etape from "@/models/etapes/Etape";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Tag from "primevue/tag";
import Button from "primevue/button";
import Card from "primevue/card";
import apiClient from "@/api-client";
import InputSwitch from 'primevue/inputswitch';
import ColorPicker from 'primevue/colorpicker';

export default defineComponent({
  name: "EtapesPage",
  components: {
    DataTable,
    Column,
    Button,
    Card,
    InputSwitch,
    ColorPicker,
  },
  setup() {
    const usersStore = useUsersStore();
    const etapes = ref<Etape[]>()
    onBeforeMount(() => {
      apiClient.etapes
        .getEtapes()
        .then((data: Etape[]) => {
          etapes.value = data.map((etape) => Object.assign(new Etape(), etape));
        });
    });

    const onSelectEtape = (etape: Etape) => {
      console.log('ok')
    };

    return {
      etapes,
      onSelectEtape,
      can: usersStore.can,
      appUser: usersStore.state.appUser,
    };
  },
});
</script>