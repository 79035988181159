
import { defineComponent, ref, PropType } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Card from "primevue/card";
import Button from "primevue/button";
import { AuditInterface } from "@/models/audits/Audit.interface";
import moment from 'moment'

export default defineComponent({
  components: {
    Card,
    DataTable,
    Column,
    Button,
  },
  props: {
    audits: {
      type: Array as PropType<AuditInterface[]>
    }
  },
  setup(props) {
    return {
      moment
    };
  },
});
