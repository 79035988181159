
import { defineComponent, computed, ref } from "vue";
import Card from "primevue/card";
import { useUsersStore } from "@/store/users";
import InputSwitch from 'primevue/inputswitch';


export default defineComponent({
  components: {
    Card,
    InputSwitch,
  },
  props: {
    leadId: {
      type: String,
    },
  },
  setup() {
    const usersStore = useUsersStore();
    const appEnvName = computed(() => {
      return process.env.VUE_APP_ENV;
    });

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    const getColor = (environement: string) => {
      switch(environement) {
        case 'developpement':
          return "var(--purple-400)";
        case 'test':
          return "var(--green-400)";
        case 'production':
          return "#cc0000";
      }
    }

    const appStyle = computed(() => {
      return {
        color: getColor(process.env.VUE_APP_ENV),
      };
    });

    const apiStyle = computed(() => {
      return {
        color: getColor(usersStore.state.appUser.environement),
      };
    });

    const dbStyle = computed(() => {
      return {
        color: getColor(usersStore.state.appUser.database),
      };
    });

    const modeDebug = ref(false);

    return {
      appStyle,
      apiStyle,
      appEnvName,
      appUser,
      dbStyle,
      modeDebug,
      can: usersStore.can,
    };
  },
});
