
import { defineComponent, computed, ref } from "vue";
import DashboardLeftMenu from "@/layouts/DashboardLeftMenu.vue"
import DahboardTopbar from "@/layouts/DashboardTopbar.vue";
import AppUserSimulationInfo from "@/components/app/AppUserSimulationInfo.vue"
import { useRoute } from "vue-router";

export default defineComponent({
  name: "DashboardLayout",
  components: {
    DashboardLeftMenu,
    DahboardTopbar,
    AppUserSimulationInfo,
  },
  setup() {
    const route = useRoute();
    const home = {icon: 'pi pi-home', to: '/'};
    const isMenuHidden = ref(false);
    const toggleDisplayMenu = () => {
      isMenuHidden.value = !isMenuHidden.value;
    }
    
    //  const items = computed(() => {
    //   const breadcrumbItems = route.meta.breadcrumbItems as {
    //     label: string
    //     to: string
    //   }[];
    //   return breadcrumbItems;
    // });

    return {
      home,
      //items,
      isMenuHidden,
      toggleDisplayMenu,
    };
  },
});
