
import { defineComponent, ref, computed, watch } from "vue";
import { useUsersStore } from "@/store/users";
import { MutationType } from "@/models/store";
import { useToast } from "primevue/usetoast";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import apiClient from "@/api-client";

export default defineComponent({
  name: "Login",
  components: {
    InputText,
    Button,
  },
  setup() {
    const username = ref("");
    const password = ref("");
    const usersStore = useUsersStore();
    const toast = useToast();

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    const loading = computed(() => {
      return usersStore.state.loading;
    });

    const onClick = () => {
      usersStore.action(MutationType.users.loadAppUser, {
        username: username.value,
        password: password.value,
      });
    };

    const resetPassword = () => {
      if(!username.value) {
        toast.add({
          severity: "warn",
          summary: "Adresse mail manquante",
          detail: "Vous devez renseigner votre adresse mail pour recevoir les instructions.",
          life: 5000,
        });

        return;
      }

      apiClient.users.resetPassword(username.value).then(() => {
        toast.add({
          severity: "info",
          summary: "Réinitialisation de votre mot de passe",
          detail: "Un email vient de vous être envoyé pour chnager votre mot de passe, merci de suivre les instructions.",
          life: 5000,
        });
      });
    };

    return {
      appUser,
      loading,
      username,
      password,
      onClick,
      resetPassword,
    };
  },
});
