<template>
  <div class="modele-projet-form">
    <ConfirmDialog></ConfirmDialog>
    <Card>
      <template #content>
        <div class="p-grid">
          <div class="p-col p-text-left">
            <h3>
              <router-link to="/modeles_projet">
                  <Button class="p-button-outlined p-button-sm" icon="pi pi-arrow-left" />
              </router-link>
              Détail d'un modèle de projet
            </h3>
          </div>
          <div class="p-col p-text-right">
            <router-link
              :to="{
                name: 'AuditsPage',
                params: {
                  table: 'spirit_spirit_modeleprojet',
                  id: modele_projet.id,
                },
              }"
            >
              <Button
                label="Audit"
                icon="pi pi-file-excel"
                iconPos="right"
                class="p-button-help"
              />
            </router-link>
          </div>
        </div>
        <div class="p-fluid">
          <div class="p-field p-grid" v-if="appUser.is_mode_debug && can('exec_modedebug')">
            <label for="id" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              <font-awesome-icon icon="key" />
            </label>
            <div class="p-col-12 p-md-10">
              <InputText
                disabled
                v-model="modele_projet.id"
                id="id"
                type="text"
              />
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              Modèle de projet
            </label>
            <div class="p-col-12 p-md-10">
              <InputText v-model="modele_projet.name" id="name" type="text" />
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="dureeJours" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              Durée estimée en jours
            </label>
            <div class="p-col-12 p-md-10">
              <InputText v-model="modele_projet.dureeJours" id="dureeJours" type="number" />
            </div>
          </div>
          <div class="p-field p-grid">
            <label for="products" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
              Articles associés
            </label>
            <div class="p-col-12 p-md-10">
              <MultiSelect v-model="modele_projet.products" :options="products" optionLabel="longName" optionValue="id" placeholder="Sélectionnez les articles" display="chip"/>
            </div>
          </div>
        </div>

        <div class="p-col p-text-left" v-if="modele_projet.id != 'notSet'">
              <Button
                @click="newEtape"
                label="Ajouter une étape au modèle"
                icon="pi pi-plus"
                iconPos="left"
                class="p-button-success"
              />
        </div>

        <div v-for="etapeModele, idx in modele_projet.etapesModele" v-bind:key="etapeModele">
              {{etapeModele}} {{idx}}
              <ModeleProjetEtapeForm :etapeModeleId="etapeModele" :bSave="saveAll" :modele_projet="modele_projet" :idx="idx" />
              <hr/>

        </div>

      </template>
      <template #footer>
        <Button
          @click="saveModeleProjet"
          label="Enregistrer"
          icon="pi pi-save"
          iconPos="right"
        />
        <Button
          @click="deleteModeleProjet"
          label="Supprimer"
          class="p-button-danger"
          icon="pi pi-trash"
          iconPos="right"
        />
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import ModeleProjet from "@/models/modele_projet/Modele_projet";
import Product from "@/models/product/Product";
import apiClient from "@/api-client";
import router from "@/router";
import Card from "primevue/card";
import { useUsersStore } from "@/store/users";
import MultiSelect from 'primevue/multiselect';
import ModeleProjetEtapeForm from '@/components/modele_projet_etape/ModeleProjetEtapeForm.vue'

export default defineComponent({
  components: {
    InputText,
    Button,
    Card,
    MultiSelect,
    ModeleProjetEtapeForm,
  },
  props: {
    modeleProjetId: {
      type: String,
    },
  },
  setup(props) {
    const toast = useToast();
    const confirm = useConfirm();
    const modele_projet = ref<ModeleProjet>();
    const usersStore = useUsersStore();
    const products = ref<Product[]>();
    const saveAll = ref<boolean>();

    saveAll.value = false;

    const appUser = computed(() => {
      return usersStore.state.appUser;
    });

    apiClient.product
        .getProducts()
        .then((data: Product[]) => {
          products.value = data.map((product) => Object.assign(new Product(), product));
        });

    modele_projet.value = new ModeleProjet();
    modele_projet.value.id = props.modeleProjetId!;

    if (props.modeleProjetId != 'notSet') {
      apiClient.modeles_projet
        .getModeleProjet(modele_projet.value!)
        .then((data: ModeleProjet) => {
          modele_projet.value = Object.assign(new ModeleProjet(),data);
        });
    }

    const newEtape = () => {
      modele_projet.value!.etapesModele!.push('notSet');
    };

    const saveModeleProjet = () => {
      saveAll.value = true;
      const id = modele_projet.value!.id;
      apiClient.modeles_projet
        .sendModeleProjet(modele_projet.value!)
        .then((data: ModeleProjet) => {
          modele_projet.value = data;
          toast.add({
            severity: "success",
            summary: "Enregistré",
            detail: "Le modèle de projet a été enregistré avec succès.",
            life: 5000,
          });
          saveAll.value = false;
          if (id != 'notSet') {
            router.push({ path: "/modeles_projet" });
          } else {
            router.push({ path: "/modele_projet/" + modele_projet.value!.id });
          }
        });
    };

    const deleteModeleProjet = () => {
      confirm.require({
        message: "Etes vous sûr de vouloir supprimer ce modèle de projet ?",
        header: "Suppression",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          apiClient.modeles_projet
            .deleteModeleProjet(modele_projet.value!)
            .then((data: ModeleProjet) => {
              modele_projet.value = data;
              toast.add({
                severity: "success",
                summary: "Suppression",
                detail: "Le modèle de projet a été supprimé.",
                life: 5000,
              });
              router.push({ path: "/modeles_projet" });
            });
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    };

    return {
      modele_projet,
      saveModeleProjet,
      deleteModeleProjet,
      newEtape,
      appUser,
      can: usersStore.can,
      products,
      saveAll,
    };
  },
});
</script>

<style lang="scss">
.modele-projet-form {
  margin: 0px;
  .p-card-footer {
    text-align: end;
    .p-button {
      margin: 10px !important;
    }
  }
}

</style>