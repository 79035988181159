export default class Etape {
    id: string
    name?: string
    couleur?: string
    for_operateur?: boolean
    for_referent?: boolean

    constructor(){
        this.id = 'notSet'
        this.name = '';
        this.couleur = '#FFFFFF';
        this.for_operateur = false;
        this.for_referent = false;
    }
}