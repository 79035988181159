import { ModelesProjetEtapeApiClientModel, ModelesProjetEtapeApiClientInterface, ModelesProjetEtapeApiClientUrlsInterface } from '@/models/api-client/modele_projet_etape'

const urls: ModelesProjetEtapeApiClientUrlsInterface = {
    getModeleProjetEtape: process.env.VUE_APP_API_URL + '/api/modele_projets/etapes/$(modele_projet_etapeId)',
    postModeleProjetEtape: process.env.VUE_APP_API_URL + '/api/modele_projets/$(modele_projetId)/etapes',
    putModeleProjetEtape: process.env.VUE_APP_API_URL + '/api/modele_projets/etapes/$(modele_projet_etapeId)',
    deleteModeleProjetEtape: process.env.VUE_APP_API_URL + '/api/modele_projets/etapes/$(modele_projet_etapeId)'
}

const modeles_projet_etapeApiClient: ModelesProjetEtapeApiClientInterface = new ModelesProjetEtapeApiClientModel(urls)
// export our instance
export default modeles_projet_etapeApiClient
