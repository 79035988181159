import { UtilisateursApiClientUrlsInterface } from './UtilisateursApiClientUrls.interface'
import { UtilisateursApiClientInterface } from './UtilisateursApiClient.interface'
import { UtilisateurInterface } from '@/models/utilisateurs/Utilisateur.interface'
import { HttpClient, HttpRequestParamsInterface } from '@/models/http-client'


/**
* @Name UtilisateursApiClientModel
* @description
* Implements the UtilisateursApiClientInterface interface
*/
export class UtilisateursApiClientModel implements UtilisateursApiClientInterface {
    private readonly urls!: UtilisateursApiClientUrlsInterface
    constructor(urls: UtilisateursApiClientUrlsInterface) {
        this.urls = urls
    }

    fetchUtilisateurs(): Promise<UtilisateurInterface[]> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.fetchUtilisateurs,
            requiresToken: false,
        }
        return HttpClient.get<UtilisateurInterface[]>(getParameters)
    }

    fetchUtilisateur(utilisateur: UtilisateurInterface): Promise<UtilisateurInterface> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.fetchUtilisateur.replace('$(utilisateurId)', utilisateur.id),
            requiresToken: false,
        }
        return HttpClient.get<UtilisateurInterface>(getParameters)
    }

    sendUtilisateur(utilisateur: UtilisateurInterface): Promise<UtilisateurInterface> {
        if (utilisateur.id) {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.putUtilisateur.replace('$(utilisateurId)', utilisateur.id),
                requiresToken: false,
                payload: utilisateur,
            }
            return HttpClient.put<UtilisateurInterface>(getParameters)
        } else {
            const getParameters: HttpRequestParamsInterface = {
                url: this.urls.postUtilisateur.replace('$(utilisateurId)', utilisateur.id),
                requiresToken: false,
                payload: utilisateur,
            }
            return HttpClient.post<UtilisateurInterface>(getParameters)
        }
    }

    deleteUtilisateur(utilisateur: UtilisateurInterface): Promise<UtilisateurInterface> {
        const getParameters: HttpRequestParamsInterface = {
            url: this.urls.deleteUtilisateur.replace('$(utilisateurId)', utilisateur.id),
            requiresToken: false,
        }
        return HttpClient.delete<UtilisateurInterface>(getParameters)
    }
}
