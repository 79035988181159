<template>
  <div class="dashboard-topbar">
      <div class="p-grid">
        <div class="p-col-1">
          <Button @click="toggleDisplayMenu" icon="pi pi-bars" class="p-button-outlined p-button-secondary button-left-menu" />
          <router-link to="/" class="logo-link p-d-none p-d-md-inline-flex">
            <img
              class="logo"
              alt="apollo-layout"
              src="assets/layout/images/bandeau_top.jpg"
            />
          </router-link>
        </div>
        <div class="p-col-11 p-text-right">
          <SplitButton
            :label="user.email"
            :model="appUserItems"
            icon="pi pi-user"
            iconPos="left"
            class="p-button-success p-mb-2"
          >
          </SplitButton>
        </div>
      </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
//import Menubar from "primevue/menubar";
import SplitButton from "primevue/splitbutton";
import { MutationType } from "@/models/store";
import router from "@/router";
import { useUsersStore } from "@/store/users";
import Button from "primevue/button";

export default defineComponent({
  name: "DashboardTopbar",
  components: {
    SplitButton,
    Button,
  },
  props: {
    toggleDisplayMenu: Function,
  },
  setup() {
    const usersStore = useUsersStore();
    const user = computed(() => {
      return usersStore.state.appUser;
    });

    const items = null;

    const appUserItems = computed(() => {
      return [
        {
          label: "Se déconnecter",
          icon: "pi pi-sign-out",
          command: () => {
            usersStore.action(MutationType.users.logoutAppUser, {});
            router.push({ path: "/login" });
          },
        },
      ];
    });

    return {
      appUserItems,
      user,
      items,
    };
  },
});
</script>

<style lang="scss">
.dashboard-topbar {
  background-color: #1a171b;

  .logo {
    height: 36px;
    margin-left: 50px;
  }

  .p-grid {
    margin: 0 10px 10px 10px;
  }

  .button-left-menu {
    position: absolute;
  }
}
</style>